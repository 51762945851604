body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Verdana, Geneva, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  max-width: 500px;
}

/** for fixed left column table  **/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.intro {
  max-width: 1280px;
  margin: 1em auto;
}
.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: scroll;
  height: 600px;
}

.table-scroll table {
  width: 100%;
  min-width: 1280px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}

.table-scroll thead th {
  background: #5e6478;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/* safari and ios need the tfoot itself to be position:sticky also */

.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: #666;
  color: #fff;
  z-index: 4;
}

a:focus {
  color: #5a9fd6;
} /* testing links*/

.table-scroll tbody th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: rgb(230, 234, 235);
}
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}

**/
/** collapsible styles **/


.lbl-toggle {
  display: block;
  cursor: pointer;
  border-radius: 7px;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: #c9ced5;
}
.lbl-toggle::before {
  content: ' ';
  display: inline-block;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;

  vertical-align: middle;
  margin-right: 0.7rem;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}
.collapsible-content .content-inner {
  padding: 0.5rem;
}
.collapsible-content {
  max-height: 0px;
  overflow: hidden;

  -webkit-transition: max-height 0.25s ease-in-out;

  transition: max-height 0.25s ease-in-out;
}
.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: none;
}

.toggle:checked + .lbl-toggle::before {
  -webkit-transform: rotate(90deg) translateX(-3px);
          transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

input.collapsible[type='checkbox'] {
  display: none;
}

/** collapsible styles **/

/** 
.collapse {
  cursor: pointer;
  display: block;
  background: #cdf;
}
.collapse + input {
  display: none; 
}
.collapse + input + div {
  display: none;
}
.collapse + input:checked + div {
  display: block;
}

/** **/

.gray {
  color: #828282;
}

.lightcoral {
  background-color: lightcoral;
}

.orange {
  background-color: #ff6600;
}

.background-gray {
  background-color: rgb(246, 246, 239);
}

.f11 {
  font-size: 11px;
}

.w85 {
  width: 85%;
}

.button {
  font-family: monospace;
  font-size: 10pt;
  color: black;
  background-color: buttonface;
  text-align: center;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  cursor: pointer;
  max-width: 250px;
}

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: darkslategrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: lightblue; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.color-0 {
  background-color: #a28c9b; }

.color-1 {
  background-color: #e9dd8e; }

.color-2 {
  background-color: #b2c6d3; }

.color-3 {
  background-color: #dcc5b9; }

.color-4 {
  background-color: #758d87; }

.color-5 {
  background-color: #8a8682; }

.color-6 {
  background-color: #c58d96; }

.color-7 {
  background-color: #b2cac0; }

.color-8 {
  background-color: #e1a77e; }

.color-9 {
  background-color: #91b6bb; }

.color-10 {
  background-color: #8ca293; }

.f-color-0 {
  color: #a28c9b;
  background-color: #a28c9b; }

.f-color-10 {
  color: #8ca293;
  background-color: #8ca293; }

.hue-color-10 {
  background-color: #8ca29a; }

.hue-color-0 {
  background-color: #a28c94; }

.lighten-color-10 {
  background-color: #e1e6e2; }

.lighten-color-0 {
  background-color: #e6e1e5; }

.lighten2-color-10 {
  background-color: #c4d0c8; }

.lighten2-color-0 {
  background-color: #d0c4cc; }

.color-11 {
  background-color: #8e9ae9; }

.f-color-1 {
  color: #e9dd8e;
  background-color: #e9dd8e; }

.f-color-11 {
  color: #8e9ae9;
  background-color: #8e9ae9; }

.hue-color-11 {
  background-color: #a08ee9; }

.hue-color-1 {
  background-color: #d7e98e; }

.lighten-color-11 {
  background-color: white; }

.lighten-color-1 {
  background-color: white; }

.lighten2-color-11 {
  background-color: #e3e6fa; }

.lighten2-color-1 {
  background-color: #faf7e3; }

.color-12 {
  background-color: #d3bfb2; }

.f-color-2 {
  color: #b2c6d3;
  background-color: #b2c6d3; }

.f-color-12 {
  color: #d3bfb2;
  background-color: #d3bfb2; }

.hue-color-12 {
  background-color: #d3cab2; }

.hue-color-2 {
  background-color: #b2bbd3; }

.lighten-color-12 {
  background-color: white; }

.lighten-color-2 {
  background-color: white; }

.lighten2-color-12 {
  background-color: #f8f5f3; }

.lighten2-color-2 {
  background-color: #f3f6f8; }

.color-13 {
  background-color: #b9d0dc; }

.f-color-3 {
  color: #dcc5b9;
  background-color: #dcc5b9; }

.f-color-13 {
  color: #b9d0dc;
  background-color: #b9d0dc; }

.hue-color-13 {
  background-color: #b9c4dc; }

.hue-color-3 {
  background-color: #dcd1b9; }

.lighten-color-13 {
  background-color: white; }

.lighten-color-3 {
  background-color: white; }

.lighten2-color-13 {
  background-color: #fdfefe; }

.lighten2-color-3 {
  background-color: #fefdfd; }

.color-14 {
  background-color: #8d757b; }

.f-color-4 {
  color: #758d87;
  background-color: #758d87; }

.f-color-14 {
  color: #8d757b;
  background-color: #8d757b; }

.hue-color-14 {
  background-color: #8d7775; }

.hue-color-4 {
  background-color: #758b8d; }

.lighten-color-14 {
  background-color: #d2c9cb; }

.lighten-color-4 {
  background-color: #c9d2d0; }

.lighten2-color-14 {
  background-color: #bbadb0; }

.lighten2-color-4 {
  background-color: #adbbb8; }

.color-15 {
  background-color: #82868a; }

.f-color-5 {
  color: #8a8682;
  background-color: #8a8682; }

.f-color-15 {
  color: #82868a;
  background-color: #82868a; }

.hue-color-15 {
  background-color: #82838a; }

.hue-color-5 {
  background-color: #8a8982; }

.lighten-color-15 {
  background-color: #d1d3d4; }

.lighten-color-5 {
  background-color: #d4d3d1; }

.lighten2-color-15 {
  background-color: #b7b9bb; }

.lighten2-color-5 {
  background-color: #bbb9b7; }

.color-16 {
  background-color: #8dc5bc; }

.f-color-6 {
  color: #c58d96;
  background-color: #c58d96; }

.f-color-16 {
  color: #8dc5bc;
  background-color: #8dc5bc; }

.hue-color-16 {
  background-color: #8dbbc5; }

.hue-color-6 {
  background-color: #c5978d; }

.lighten-color-16 {
  background-color: #f2f9f8; }

.lighten-color-6 {
  background-color: #f9f2f3; }

.lighten2-color-16 {
  background-color: #d1e7e4; }

.lighten2-color-6 {
  background-color: #e7d1d4; }

.color-17 {
  background-color: #cab2bc; }

.f-color-7 {
  color: #b2cac0;
  background-color: #b2cac0; }

.f-color-17 {
  color: #cab2bc;
  background-color: #cab2bc; }

.hue-color-17 {
  background-color: #cab2b4; }

.hue-color-7 {
  background-color: #b2cac8; }

.lighten-color-17 {
  background-color: white; }

.lighten-color-7 {
  background-color: white; }

.lighten2-color-17 {
  background-color: #f4eef1; }

.lighten2-color-7 {
  background-color: #eef4f1; }

.color-18 {
  background-color: #7eb8e1; }

.f-color-8 {
  color: #e1a77e;
  background-color: #e1a77e; }

.f-color-18 {
  color: #7eb8e1;
  background-color: #7eb8e1; }

.hue-color-18 {
  background-color: #7e97e1; }

.hue-color-8 {
  background-color: #e1c87e; }

.lighten-color-18 {
  background-color: #fafcfe; }

.lighten-color-8 {
  background-color: #fefcfa; }

.lighten2-color-18 {
  background-color: #d1e6f4; }

.lighten2-color-8 {
  background-color: #f4dfd1; }

.color-19 {
  background-color: #bb9691; }

.f-color-9 {
  color: #91b6bb;
  background-color: #91b6bb; }

.f-color-19 {
  color: #bb9691;
  background-color: #bb9691; }

.hue-color-19 {
  background-color: #bba491; }

.hue-color-9 {
  background-color: #91a8bb; }

.lighten-color-19 {
  background-color: #f5f0f0; }

.lighten-color-9 {
  background-color: #f0f5f5; }

.lighten2-color-19 {
  background-color: #e2d2d0; }

.lighten2-color-9 {
  background-color: #d0e0e2; }

.color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 5px; }

.black {
  color: black; }

.red {
  color: #e73754; }

.green {
  color: #205f29; }

.lighten2 {
  -webkit-filter: brightness(160%);
          filter: brightness(160%); }

.lighten {
  -webkit-filter: brightness(140%);
          filter: brightness(140%); }

.darken {
  -webkit-filter: brightness(70%);
          filter: brightness(70%); }

.info-title {
  color: #494b4f; }

.main-box {
  border-color: rgba(0, 0, 0, 0.2);
  border-top-width: 1px;
  border-top-style: solid;
  background-color: #f7f8fc; }

.thumbnail_title {
  color: #445a7c;
  text-decoration: none; }

.bg-projects {
  background-color: #f7f8fc;
  border-left-style: solid;
  border-left-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(0, 0, 0, 0.1); }

.project-info {
  margin: 1rem;
  border-radius: 0.25rem;
  background-color: white;
  padding: 2rem;
  border-style: solid;
  border-width: 1px;
  max-width: 24rem; }

.work-planner {
  margin: 1rem;
  border-radius: 0.25rem;
  background-color: white;
  padding: 1rem;
  border-style: solid;
  border-width: 1px;
  min-height: 24rem; }

.project_header {
  float: left;
  color: #445a7c;
  font-size: 1.2rem;
  font-weight: bold; }

.header {
  background-image: -webkit-gradient(linear, left top, right top, from(#f7f8fc), to(#9eaea4));
  background-image: -webkit-linear-gradient(left, #f7f8fc, #9eaea4);
  background-image: linear-gradient(to right, #f7f8fc, #9eaea4);
  color: #50678c;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold; }

.w12 {
  width: 12rem; }

.close {
  position: relative;
  float: right;
  width: 17px;
  height: 17px;
  opacity: 0.3;
  border: none;
  margin: 0.5rem; }

.close:hover {
  opacity: 1; }

.close:before,
.close:after {
  position: absolute;
  top: 2px;
  left: 5px;
  content: ' ';
  height: 12px;
  width: 2px;
  background-color: #333; }

.close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.blur > * {
  -webkit-filter: blur(5px);
          filter: blur(5px); }

.unblur {
  -webkit-filter: blur(0px);
          filter: blur(0px); }

.popup {
  margin: auto;
  height: 45%;
  width: 40%;
  border-radius: 9px;
  background-color: #f6f8fb;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e3e7; }

.popup .details {
  background-color: #ffffff; }

.popup .title {
  color: #4f5256;
  font-family: Poppins;
  font-size: 12px;
  line-height: 24px; }

.popup .value {
  color: #4f5256;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px; }

.popup .photo {
  height: 90px;
  width: 90px; }

.rectangle-copy {
  width: 100%;
  background-color: #f6f8fb;
  min-height: 4200px;
}

.rectangle-copy-3 {
  width: 85%;
  border: 1px solid #e7e7e8;
  background-color: #ffffff;
  padding-bottom: 1rem;
}

.rectangle-copy-1 {
  background: #ffffff;
}
.rectangle-copy-4 {
  width: 25%;
  border: 1px solid #e7e7e8;
  background-color: #ffffff;
  float: left;
  height: 1000px;
}

/* .group {
  width: 30%;
} */

.logo {
  height: 2.5rem;
  width: 4.5rem;
  /* background-color: #4f688b; */
  margin-left: 2rem;
  vertical-align: middle;
}

.resource-planner {
  color: #4f688b;
  font-family: Arial;
  font-size: 17px;
  vertical-align: middle;
}

.main-box {
  min-height: 3500px;
  width: 100%;
  border: 2px solid #e7e7e8;
  border-radius: 6.3px;
  background-color: #ffffff;
  padding: 2rem 0 2rem 0.5rem;
  position: relative;
  overflow: auto;
}

.top-link {
  color: #4f688b;
  font-family: Arial;
  font-size: normal;
  font-weight: 500;
  line-height: 2rem;
}
.rectangle-2 {
  height: 40px;
  width: 286px;
  border: 2px solid #e7e7e8;
  border-radius: 5px;
  background-color: #ffffff;
}

.group-5-copy-3 {
  padding: 1rem 1rem 1rem 2rem;
  border-bottom: 2px solid #e7e7e8;
}

.projectNumber {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 21px;
  white-space: nowrap;
}

.projectName {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.project-page-heading {
  color: #4f688b;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 600;
  margin: 2rem 0 1rem 1.5rem;
}

.project-detail-heading {
  color: #4f5256;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.project-info-title {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
}

.project-info-value {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 600;
}

.rectangle-14 {
  width: 40%;
  min-width: 350px;
  border: 2px solid #e7e7e8;
  border-radius: 6.3px;
  background-color: #ffffff;
  margin: 1rem 1rem 1rem 2rem;
  padding: 1.5rem;
}

.progress {
  color: #4f5256;
  font-family: Arial;
  font-size: smaller;
  font-weight: bold;
  line-height: 1.3rem;
  padding: 1.5rem 0 0.5rem 0;
}

.mask-copy {
  height: 10px;
  width: 13%;
  border-radius: 5px;
  background-color: #90d368;
  position: absolute;
}

.rectangle-6 {
  height: 10px;
  width: 263px;
}

.mask {
  height: 10px;
  width: 95%;
  border-radius: 5px;
  background-color: #f0f0f0;
  z-index: -2;
}

.menu-item {
  color: #4f5256;
  font-family: Arial;
  font-size: small;
  padding: 1rem 1rem 1rem 2rem;
}

.employee-box {
  width: 196px;
  height: 266px;
  border: 2px solid #e9ebed;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1rem;
  margin: 0.5rem;
  text-align: center;
}

.employee-box:hover {
  background-color: #5a9fd6;
}

.line-2 {
  box-sizing: border-box;
  height: 2px;
  width: 23px;
  border: 1px solid #e0e6ed;
  margin: 0.5rem auto 0.7rem auto;
  vertical-align: center;
}

.line-3 {
  box-sizing: border-box;
  height: 27px;
  width: 3px;
  border: 1px solid #e7e7e8;
  vertical-align: middle;
  margin-right: 1rem;
  display: inline-block;
}

.employee-name {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  font-weight: 600;
  padding-top: 1rem;
  line-height: 1rem;
}

.photo {
  height: 5rem;
  width: 5rem;
  margin-top: 0.5rem;
}

.employee-link {
  text-decoration: none;
}
.employee-details {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
  line-height: 1.2rem;
}

.filter-box {
  width: 23%;
  padding: 1rem;
  float: left;
}

::-webkit-input-placeholder {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2rem;
}

::-moz-placeholder {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2rem;
}

:-ms-input-placeholder {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2rem;
}

::-ms-input-placeholder {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2rem;
}

::placeholder {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2rem;
}

.search-box {
  width: 100%;
  height: 2rem;
  border: 2px solid #e7e7e8;
  border-radius: 5px;
  background-color: #ffffff;
  display: block;
  margin: 1rem 0;
  padding-left: 1rem;
}

/* pagination */
ul.pagination {
  display: flex;
  list-style-type: none;
  margin: 0 auto 0 auto;
  justify-content: center;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
ul.pagination li.page-item.active {
  color: #ffffff !important;
  background-color: #5a9fd6 !important;
}
/* ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
  font-weight: 900;
  font-size: 1rem;
} */
.page-item:hover {
  background-color: #f4f4f4;
}

.page-item {
  height: 43px;
  width: 43px;
  margin-right: 0.5rem;
  border: 2px solid #e7e7e8;
  background-color: #ffffff;
  border-radius: 50%;
}

.page-link {
  height: 17px;
  width: 40px;
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
  line-height: 43px;
  text-align: center;
  vertical-align: middle;
}

/* .page-item .sr-only {
  display: none;
} */
/* pagination */

.details {
  margin: 1rem auto 5rem auto;
  min-height: 838px;
  border: 2px solid #e7e7e8;
  border-radius: 6.3px;
  background-color: #ffffff;
  overflow: auto;
}

.details .employee-name {
  color: #4f5256;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 600;
}

.back-arrow {
  height: 24px;
  width: 24px;
  color: #e7e7e8;
}

.back {
  height: 17px;
  width: 53px;
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 18px;
}

.line-4 {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 0.5px solid #e7e7e8;
  margin-top: 0.5rem;
}

.details .add {
  height: 1.8rem;
  line-height: 1.8rem;
  width: 135px;
  border-radius: 5px;
  background-color: #5f7a9c;
  color: #ffffff;
  font-family: Arial;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: middle;
  margin-top: 1rem;
}

.details .title {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  line-height: 1.2rem;
}

.details .value {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.2rem;
}

/** rate hover **/
.details .rate {
  color: #5a9fd6;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
}

.rate-box1,
.rate-box2 {
  width: 200px;
  height: 1.2rem;
}

.rate-box1 {
  display: block;
}

.rate-box2 {
  display: none;
}

.wrapper {
  width: auto;
  display: inline-block;
}

.wrapper:hover .rate-box1 {
  display: none;
}

.wrapper:hover .rate-box2 {
  display: block;
}
/** rate hover **/

.sort-box {
  height: 2rem;
  width: 150px;
  border: 2px solid #e7e7e8;
  border-radius: 5px;
  background-color: #ffffff;
}

.sort-box option {
  min-height: 1.5rem;
  width: 210px;
}
.sort-box option:active {
  background-color: #f6f8fb;
}

.sort-by {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.bullpen {
  width: 75%;
}
.filter-column {
  position: absolute;
  top: 4%;
  padding: 0.5rem;
  width: 23%;
  /* overflow-y: auto; */
}

.filter-title {
  color: #4f688b;
  font-family: Arial;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.filter-number {
  color: #b3b8be;
  font-family: Arial;
  font-size: 12px;
}

.checkbox-rectangle {
  height: 17px;
  width: 17px;
  border: 1px solid #979797;
  border-radius: 2px;
}

input.checkbox-rectangle:checked {
  background-color: #4f688b;
}

/** employee details tab css */

/* .tabHolder {
  width: 100%;
  border-bottom: 1px solid #e0e3e7;
} */

.tabHolder .tab-item {
  list-style: none;
  display: inline-block;
  width: 125px;
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  line-height: 1.2rem;
  text-align: center;
  border: 1px solid #e0e3e7;
  padding: 0.5rem;
}

.tabHolder li.active {
  /* border-bottom: 1px solid #ffffff; */
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tabHolder li.active a {
  font-size: 13px;
  font-weight: 800;
}

.tabHolder li.inactive {
  border: 1px solid #ffffff;
  border-bottom: 1px solid #e0e3e7;
}

.tabHolder li.disabled {
  color: #a7a9ac;
}

/** employee details tab css */

.arrow-list {
  height: 12px;
  width: 7.41px;
  color: #5a9fd6;
  padding: 1rem;
  font-weight: 600;
}

.license {
  color: #5a9fd6;
  font-family: Arial;
  font-size: 12px;
  line-height: 27px;
  font-weight: 600;
}
.license-sub {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 27px;
}

.registrations li {
  list-style-type: none;
}

.tn_icons {
  position: relative;
}

.employee-box .tn_icons:hover {
  background: none;
}
.partTime {
  height: 15.94px;
  width: 16.11px;
}

.onLeave {
  height: 17.42px;
  width: 15.83px;
}
.tn_icons .partTime {
  position: absolute;
  top: 0;
  left: 0;
}
.tn_icons .onLeave {
  position: absolute;
  top: 0;
  right: 0;
}

.line-5 {
  margin: 0.5rem 0;
  box-sizing: border-box;
  height: 1px;
  width: 163px;
  border: 0.75px solid #e7e7e8;
}

/** projects table **/

.projects tr {
  height: 40px;
}

table.projects {
  border-collapse: collapse;
  width: 85%;
}
table.projects tr {
  /* box-sizing: border-box; */
  /* height: 1px;
  width: 100%; */
  border-bottom: 2px solid #e7e7e8;
}

table.projects tbody td {
  padding-left: 1.5rem;
}
table.projects th div {
  display: inline-block;
}
table.projects .sort {
  /* height: 31px;
  width: 37px; */
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
table.projects .active {
  color: #5f7a9c;
}

table.projects th {
  text-align: left;
  margin-left: 0.5rem;
}
/** projects table **/

/** spinner **/

.spinner {
  border: 4px solid #e7e7e8; /* Light grey */
  border-top: 4px solid #5f7a9c; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: auto;
  margin-top: 20%;
  vertical-align: middle;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

.spinner-text {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 21px;
  white-space: nowrap;
  position: absolute;
  left: 47%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/** spinner **/

/** range-input slider **/
.input-range__slider {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #5790d7;
  border: 1px solid #5790d7;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}

.input-range__slider-container {
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: 'Helvetica Neue', san-serif;
  font-size: 0.8rem;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #5790d7;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

.content-inner {
  min-height: 50px;
  margin-top: 1.5rem;
  vertical-align: middle;
}
/** range-input slider **/

.onleave-hover {
  height: 30px;
  width: 82px;
  border: 1px solid #e9ebed;
  border-radius: 3px;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#f6f6f6));
  background: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 100%);
  background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.03);
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding-top: 6px;
  position: absolute;
}

.partTime .onleave-hover {
  top: -40px;
  left: -25px;
}

.onLeave .onleave-hover {
  top: -40px;
  right: -38px;
}

.onleave-hover:after {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
  right: 37px;
  bottom: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f6f6f6;
  /* border-top-color: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%); */
}
.clear-filters {
  height: 18px;
  width: 174px;
  color: #ee2b2b;
  font-family: Arial;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
}

.line-search {
  box-sizing: border-box;
  height: 2rem;
  width: 1px;
  border: 0.5px solid #e7e7e8;
  position: absolute;
  right: 35px;
  bottom: 0;
  display: inline-block;
}

.search-x {
  height: 2rem;
  width: 2rem;
  color: #d8d8d8;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}

.search-x .text {
  height: 8.17px;
  width: 8.17px;
  font-size: 1rem;
  margin-left: 9px;
  margin-top: 5px;
}

.zero-results {
  color: #4f5256;
  font-family: Arial;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  margin: auto;
  margin-top: 20%;
}

.error {
  color: #e7e7e8;
  font-family: 'Font Awesome 5 Pro';
  font-size: 57px;
  font-weight: 300;
  line-height: 37px;
  position: relative;
  left: 47%;
}

.error-title {
  height: 37px;
  color: #4f5256;
  font-family: Arial;
  font-size: 27px;
  font-weight: bold;
  line-height: 37px;
  text-align: center;
}

.error-text {
  color: #4f5256;
  font-family: Arial;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.error-text a {
  color: #5790d7;
}
.error-wrapper {
  margin-top: 20%;
}

