.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: darkslategrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: lightblue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

$primary-color: #906d5c;
$secondary-color: #9eaea4;
$inherited-color: $primary-color + #111;
$highlight-color: #e1d89f;
$text-color: rgb(247, 248, 252);
$text-color-blue: rgb(68, 90, 124);
$header-color: rgb(80, 103, 140);
$dark-brown: #615d59;
$dark-title: rgb(73, 75, 79);

$colors: (
  0: #a28c9b,
  1: #e9dd8e,
  2: #b2c6d3,
  3: #dcc5b9,
  4: #758d87,
  5: #8a8682,
  6: #c58d96,
  7: #b2cac0,
  8: #e1a77e,
  9: #91b6bb
);

@each $color, $bgcolor in $colors {
  .color-#{$color} {
    // color: $bgcolor;
    background-color: $bgcolor;
  }
}
@each $color, $bgcolor in $colors {
  .color-#{$color + 10} {
    // color: complement($bgcolor);
    background-color: complement($bgcolor);
  }
  .f-color-#{$color} {
    color: $bgcolor;
    background-color: $bgcolor;
  }
  .f-color-#{$color + 10} {
    color: complement($bgcolor);
    background-color: complement($bgcolor);
  }
  .hue-color-#{$color + 10} {
    background-color: adjust-hue(complement($bgcolor), 20%);
  }
  .hue-color-#{$color} {
    background-color: adjust-hue($bgcolor, 20%);
  }
  .lighten-color-#{$color + 10} {
    background-color: lighten(complement($bgcolor), 30%);
  }
  .lighten-color-#{$color} {
    background-color: lighten($bgcolor, 30%);
  }
  .lighten2-color-#{$color + 10} {
    background-color: lighten(complement($bgcolor), 20%);
  }
  .lighten2-color-#{$color} {
    background-color: lighten($bgcolor, 20%);
  }
}

.color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 5px;
}

// @each $color, $bgcolor in $colors {
//   .hue-color-#{$color} {
//     background-color: adjust-hue($bgcolor, 20%);
//   }
// }
// @each $color, $bgcolor in $colors {
//   .hue-color-#{$color + 10} {
//     background-color: adjust-hue(complement($bgcolor), 20%);
//   }
// }

.black {
  color: black;
}

.red {
  color: #e73754;
}

.green {
  color: #205f29;
}
.lighten2 {
  filter: brightness(160%);
}

.lighten {
  filter: brightness(140%);
}

.darken {
  filter: brightness(70%);
}
.info-title {
  color: $dark-title;
}

.main-box {
  border-color: rgba(0, 0, 0, 0.2);
  border-top-width: 1px;
  border-top-style: solid;
  background-color: $text-color;
}
.thumbnail_title {
  color: $text-color-blue;
  text-decoration: none;
}
.bg-projects {
  background-color: $text-color;
  border-left-style: solid;
  border-left-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.project-info {
  margin: 1rem;
  border-radius: 0.25rem;
  background-color: white;
  padding: 2rem;
  border-style: solid;
  border-width: 1px;
  max-width: 24rem;
}

.work-planner {
  margin: 1rem;
  border-radius: 0.25rem;
  background-color: white;
  padding: 1rem;
  border-style: solid;
  border-width: 1px;
  min-height: 24rem;
}

.project_header {
  float: left;
  color: $text-color-blue;
  font-size: 1.2rem;
  font-weight: bold;
}
.header {
  background-image: linear-gradient(to right, $text-color, $secondary-color);
  color: $header-color;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.w12 {
  width: 12rem;
}
.close {
  position: relative;
  float: right;
  width: 17px;
  height: 17px;
  opacity: 0.3;
  border: none;
  margin: 0.5rem;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  top: 2px;
  left: 5px;
  content: ' ';
  height: 12px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
// .blur {
//   filter: hue-rotate(20%);
// }
.blur > * {
  // -webkit-filter: blur(2px);
  // -moz-filter: blur(2px);
  // -o-filter: blur(2px);
  // -ms-filter: blur(2px);
  filter: blur(5px);
}

.unblur {
  // z-index: 20;
  // -webkit-filter: none;
  // -moz-filter: none;
  // -o-filter: none;
  // -ms-filter: none;
  filter: blur(0px);
}

.popup {
  margin: auto;
  height: 45%;
  width: 40%;
  border-radius: 9px;
  background-color: #f6f8fb;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e3e7;
}

.popup .details {
  background-color: #ffffff;
}

.popup .title {
  color: #4f5256;
  font-family: Poppins;
  font-size: 12px;
  line-height: 24px;
}

.popup .value {
  color: #4f5256;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}

.popup .photo {
  height: 90px;
  width: 90px;
}
// .hide-child .child {
//   position: absolute;
//   top: 0;
//   left: 0;
//   padding-left: 1rem;
//   margin-top: 0.5rem;
// }
