.rectangle-copy {
  width: 100%;
  background-color: #f6f8fb;
  min-height: 4200px;
}

.rectangle-copy-3 {
  width: 85%;
  border: 1px solid #e7e7e8;
  background-color: #ffffff;
  padding-bottom: 1rem;
}

.rectangle-copy-1 {
  background: #ffffff;
}
.rectangle-copy-4 {
  width: 25%;
  border: 1px solid #e7e7e8;
  background-color: #ffffff;
  float: left;
  height: 1000px;
}

/* .group {
  width: 30%;
} */

.logo {
  height: 2.5rem;
  width: 4.5rem;
  /* background-color: #4f688b; */
  margin-left: 2rem;
  vertical-align: middle;
}

.resource-planner {
  color: #4f688b;
  font-family: Arial;
  font-size: 17px;
  vertical-align: middle;
}

.main-box {
  min-height: 3500px;
  width: 100%;
  border: 2px solid #e7e7e8;
  border-radius: 6.3px;
  background-color: #ffffff;
  padding: 2rem 0 2rem 0.5rem;
  position: relative;
  overflow: auto;
}

.top-link {
  color: #4f688b;
  font-family: Arial;
  font-size: normal;
  font-weight: 500;
  line-height: 2rem;
}
.rectangle-2 {
  height: 40px;
  width: 286px;
  border: 2px solid #e7e7e8;
  border-radius: 5px;
  background-color: #ffffff;
}

.group-5-copy-3 {
  padding: 1rem 1rem 1rem 2rem;
  border-bottom: 2px solid #e7e7e8;
}

.projectNumber {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 21px;
  white-space: nowrap;
}

.projectName {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.project-page-heading {
  color: #4f688b;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 600;
  margin: 2rem 0 1rem 1.5rem;
}

.project-detail-heading {
  color: #4f5256;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.project-info-title {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
}

.project-info-value {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 600;
}

.rectangle-14 {
  width: 40%;
  min-width: 350px;
  border: 2px solid #e7e7e8;
  border-radius: 6.3px;
  background-color: #ffffff;
  margin: 1rem 1rem 1rem 2rem;
  padding: 1.5rem;
}

.progress {
  color: #4f5256;
  font-family: Arial;
  font-size: smaller;
  font-weight: bold;
  line-height: 1.3rem;
  padding: 1.5rem 0 0.5rem 0;
}

.mask-copy {
  height: 10px;
  width: 13%;
  border-radius: 5px;
  background-color: #90d368;
  position: absolute;
}

.rectangle-6 {
  height: 10px;
  width: 263px;
}

.mask {
  height: 10px;
  width: 95%;
  border-radius: 5px;
  background-color: #f0f0f0;
  z-index: -2;
}

.menu-item {
  color: #4f5256;
  font-family: Arial;
  font-size: small;
  padding: 1rem 1rem 1rem 2rem;
}

.employee-box {
  width: 196px;
  height: 266px;
  border: 2px solid #e9ebed;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1rem;
  margin: 0.5rem;
  text-align: center;
}

.employee-box:hover {
  background-color: #5a9fd6;
}

.line-2 {
  box-sizing: border-box;
  height: 2px;
  width: 23px;
  border: 1px solid #e0e6ed;
  margin: 0.5rem auto 0.7rem auto;
  vertical-align: center;
}

.line-3 {
  box-sizing: border-box;
  height: 27px;
  width: 3px;
  border: 1px solid #e7e7e8;
  vertical-align: middle;
  margin-right: 1rem;
  display: inline-block;
}

.employee-name {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  font-weight: 600;
  padding-top: 1rem;
  line-height: 1rem;
}

.photo {
  height: 5rem;
  width: 5rem;
  margin-top: 0.5rem;
}

.employee-link {
  text-decoration: none;
}
.employee-details {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
  line-height: 1.2rem;
}

.filter-box {
  width: 23%;
  padding: 1rem;
  float: left;
}

::placeholder {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2rem;
}

.search-box {
  width: 100%;
  height: 2rem;
  border: 2px solid #e7e7e8;
  border-radius: 5px;
  background-color: #ffffff;
  display: block;
  margin: 1rem 0;
  padding-left: 1rem;
}

/* pagination */
ul.pagination {
  display: flex;
  list-style-type: none;
  margin: 0 auto 0 auto;
  justify-content: center;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
ul.pagination li.page-item.active {
  color: #ffffff !important;
  background-color: #5a9fd6 !important;
}
/* ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
  font-weight: 900;
  font-size: 1rem;
} */
.page-item:hover {
  background-color: #f4f4f4;
}

.page-item {
  height: 43px;
  width: 43px;
  margin-right: 0.5rem;
  border: 2px solid #e7e7e8;
  background-color: #ffffff;
  border-radius: 50%;
}

.page-link {
  height: 17px;
  width: 40px;
  color: #4f5256;
  font-family: Arial;
  font-size: 0.7rem;
  line-height: 43px;
  text-align: center;
  vertical-align: middle;
}

/* .page-item .sr-only {
  display: none;
} */
/* pagination */

.details {
  margin: 1rem auto 5rem auto;
  min-height: 838px;
  border: 2px solid #e7e7e8;
  border-radius: 6.3px;
  background-color: #ffffff;
  overflow: auto;
}

.details .employee-name {
  color: #4f5256;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 600;
}

.back-arrow {
  height: 24px;
  width: 24px;
  color: #e7e7e8;
}

.back {
  height: 17px;
  width: 53px;
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 18px;
}

.line-4 {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 0.5px solid #e7e7e8;
  margin-top: 0.5rem;
}

.details .add {
  height: 1.8rem;
  line-height: 1.8rem;
  width: 135px;
  border-radius: 5px;
  background-color: #5f7a9c;
  color: #ffffff;
  font-family: Arial;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: middle;
  margin-top: 1rem;
}

.details .title {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  line-height: 1.2rem;
}

.details .value {
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.2rem;
}

/** rate hover **/
.details .rate {
  color: #5a9fd6;
  text-decoration-style: solid;
}

.rate-box1,
.rate-box2 {
  width: 200px;
  height: 1.2rem;
}

.rate-box1 {
  display: block;
}

.rate-box2 {
  display: none;
}

.wrapper {
  width: auto;
  display: inline-block;
}

.wrapper:hover .rate-box1 {
  display: none;
}

.wrapper:hover .rate-box2 {
  display: block;
}
/** rate hover **/

.sort-box {
  height: 2rem;
  width: 150px;
  border: 2px solid #e7e7e8;
  border-radius: 5px;
  background-color: #ffffff;
}

.sort-box option {
  min-height: 1.5rem;
  width: 210px;
}
.sort-box option:active {
  background-color: #f6f8fb;
}

.sort-by {
  color: #d1d1d1;
  font-family: Arial;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.bullpen {
  width: 75%;
}
.filter-column {
  position: absolute;
  top: 4%;
  padding: 0.5rem;
  width: 23%;
  /* overflow-y: auto; */
}

.filter-title {
  color: #4f688b;
  font-family: Arial;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.filter-number {
  color: #b3b8be;
  font-family: Arial;
  font-size: 12px;
}

.checkbox-rectangle {
  height: 17px;
  width: 17px;
  border: 1px solid #979797;
  border-radius: 2px;
}

input.checkbox-rectangle:checked {
  background-color: #4f688b;
}

/** employee details tab css */

/* .tabHolder {
  width: 100%;
  border-bottom: 1px solid #e0e3e7;
} */

.tabHolder .tab-item {
  list-style: none;
  display: inline-block;
  width: 125px;
  color: #4f5256;
  font-family: Arial;
  font-size: 0.75rem;
  line-height: 1.2rem;
  text-align: center;
  border: 1px solid #e0e3e7;
  padding: 0.5rem;
}

.tabHolder li.active {
  /* border-bottom: 1px solid #ffffff; */
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tabHolder li.active a {
  font-size: 13px;
  font-weight: 800;
}

.tabHolder li.inactive {
  border: 1px solid #ffffff;
  border-bottom: 1px solid #e0e3e7;
}

.tabHolder li.disabled {
  color: #a7a9ac;
}

/** employee details tab css */

.arrow-list {
  height: 12px;
  width: 7.41px;
  color: #5a9fd6;
  padding: 1rem;
  font-weight: 600;
}

.license {
  color: #5a9fd6;
  font-family: Arial;
  font-size: 12px;
  line-height: 27px;
  font-weight: 600;
}
.license-sub {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 27px;
}

.registrations li {
  list-style-type: none;
}

.tn_icons {
  position: relative;
}

.employee-box .tn_icons:hover {
  background: none;
}
.partTime {
  height: 15.94px;
  width: 16.11px;
}

.onLeave {
  height: 17.42px;
  width: 15.83px;
}
.tn_icons .partTime {
  position: absolute;
  top: 0;
  left: 0;
}
.tn_icons .onLeave {
  position: absolute;
  top: 0;
  right: 0;
}

.line-5 {
  margin: 0.5rem 0;
  box-sizing: border-box;
  height: 1px;
  width: 163px;
  border: 0.75px solid #e7e7e8;
}

/** projects table **/

.projects tr {
  height: 40px;
}

table.projects {
  border-collapse: collapse;
  width: 85%;
}
table.projects tr {
  /* box-sizing: border-box; */
  /* height: 1px;
  width: 100%; */
  border-bottom: 2px solid #e7e7e8;
}

table.projects tbody td {
  padding-left: 1.5rem;
}
table.projects th div {
  display: inline-block;
}
table.projects .sort {
  /* height: 31px;
  width: 37px; */
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
table.projects .active {
  color: #5f7a9c;
}

table.projects th {
  text-align: left;
  margin-left: 0.5rem;
}
/** projects table **/

/** spinner **/

.spinner {
  border: 4px solid #e7e7e8; /* Light grey */
  border-top: 4px solid #5f7a9c; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: auto;
  margin-top: 20%;
  vertical-align: middle;
  animation: spin 2s linear infinite;
}

.spinner-text {
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 21px;
  white-space: nowrap;
  position: absolute;
  left: 47%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/** spinner **/

/** range-input slider **/
.input-range__slider {
  appearance: none;
  background: #5790d7;
  border: 1px solid #5790d7;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: 'Helvetica Neue', san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #5790d7;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

.content-inner {
  min-height: 50px;
  margin-top: 1.5rem;
  vertical-align: middle;
}
/** range-input slider **/

.onleave-hover {
  height: 30px;
  width: 82px;
  border: 1px solid #e9ebed;
  border-radius: 3px;
  background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.03);
  color: #4f5256;
  font-family: Arial;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding-top: 6px;
  position: absolute;
}

.partTime .onleave-hover {
  top: -40px;
  left: -25px;
}

.onLeave .onleave-hover {
  top: -40px;
  right: -38px;
}

.onleave-hover:after {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
  right: 37px;
  bottom: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f6f6f6;
  /* border-top-color: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%); */
}
.clear-filters {
  height: 18px;
  width: 174px;
  color: #ee2b2b;
  font-family: Arial;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
}

.line-search {
  box-sizing: border-box;
  height: 2rem;
  width: 1px;
  border: 0.5px solid #e7e7e8;
  position: absolute;
  right: 35px;
  bottom: 0;
  display: inline-block;
}

.search-x {
  height: 2rem;
  width: 2rem;
  color: #d8d8d8;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}

.search-x .text {
  height: 8.17px;
  width: 8.17px;
  font-size: 1rem;
  margin-left: 9px;
  margin-top: 5px;
}

.zero-results {
  color: #4f5256;
  font-family: Arial;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  margin: auto;
  margin-top: 20%;
}

.error {
  color: #e7e7e8;
  font-family: 'Font Awesome 5 Pro';
  font-size: 57px;
  font-weight: 300;
  line-height: 37px;
  position: relative;
  left: 47%;
}

.error-title {
  height: 37px;
  color: #4f5256;
  font-family: Arial;
  font-size: 27px;
  font-weight: bold;
  line-height: 37px;
  text-align: center;
}

.error-text {
  color: #4f5256;
  font-family: Arial;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.error-text a {
  color: #5790d7;
}
.error-wrapper {
  margin-top: 20%;
}
